/*

https://coolors.co/palette/cad2c5-84a98c-52796f-354f52-2f3e46

*/

body {
  margin: 0;
  font-family: 'Sora', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2F3E46;
  color: #CAD2C5 !important;
}


.btn-primary {
  margin-top: 20px;
  background-color: #84A98C !important;
  border-color: white !important;
  color: white !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #CAD2C5 !important;
  border-color: white !important;
  color: white !important;
}

h1 {
  font-weight: 400;
}

p {
  color: #8b91b5 !important;
}

.modal-title {
  color: #52796F !important;
}

.modal-body {
  color: #84A98C !important;
}